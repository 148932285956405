






































































































import Vue from 'vue'

import Modal from '@/components/Modal.vue'
import BaseInputVuelidate from '@/components/BaseInputVuelidate.vue'
import { messages } from '@/i18n/messages'
import { required, email, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'EditBillingInfoModal',
  components: { Modal, BaseInputVuelidate },
  props: {
    profile: {
      required: true
    }
  },
  data () {
    return {
      profileData: {
        billing_name: null,
        billing_phone: null,
        billing_email: null,
        billing_address: null,
        billing_address_2: null,
        billing_address_3: null,
        billing_vat: null
      }
    }
  },
  validations: {
    profileData: {
      billing_name: {
        required
      },
      billing_email: {
        email,
        required
      },
      billing_phone: {
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11)
      }
    }
  },
  i18n: {
    messages
  },
  mounted () {
    this.profileData.billing_name = this.profile.billing_info?.name
    this.profileData.billing_phone = this.profile.billing_info?.phone
    this.profileData.billing_email = this.profile.billing_info?.email
    this.profileData.billing_address = this.profile.billing_info?.address
    this.profileData.billing_address_2 = this.profile.billing_info?.address_2
    this.profileData.billing_address_3 = this.profile.billing_info?.address_3
    this.profileData.billing_vat = this.profile.billing_info?.vat
  },
  methods: {
    ...mapActions('profile', ['updateProfile']),
    ...mapActions('notifications', ['addNotification', 'addSuccessNotification']),
    show () {
      this.$modal.show('edit-billing-info-modal')
    },
    changeValue (data) {
      this.profileData[data.key] = data.value
    },
    save_changes () {
      this.updateProfile({
        billing_name: this.profileData.billing_name,
        billing_phone: this.profileData.billing_phone,
        billing_email: this.profileData.billing_email,
        billing_address: this.profileData.billing_address,
        billing_address_2: this.profileData.billing_address_2,
        billing_address_3: this.profileData.billing_address_3,
        billing_vat: this.profileData.billing_vat
      }).then(r => {
        this.$modal.hide('edit-billing-info-modal')
        this.addSuccessNotification('Success')
      })
        .catch(er => this.addNotification({ body: er.response.data.errors[0].detail[0], type: 'danger' }))
    }
  }
})
