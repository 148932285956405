











































import Vue from 'vue'

import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ProfileInfoBlock',
  props: {},
  computed: {
    ...mapGetters('profile', ['currentUser']),
    accountType () {
      return (+this.currentUser.account_type === 1) ? 'Personal account' : 'Business Account'
    }
  },
  methods: {
    showEditProfileModal () {
      this.$emit('showEditProfileModal')
    },
    showEditBillingInfoModal () {
      this.$emit('showEditBillingInfoModal')
    }
  }
})
