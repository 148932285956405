import http from '@/http-common'
import { AxiosResponse } from 'axios'

const baseUrl = String(process.env.VUE_APP_API_BASE_URL)

export default {
  subscribe (data): Promise<AxiosResponse> {
    return http.post(baseUrl + 'web_push', data)
  },
  update (data): Promise<AxiosResponse> {
    return http.put(baseUrl + 'web_push', data)
  },
  unsubscribe (data): Promise<AxiosResponse> {
    return http.delete(baseUrl + 'web_push', { data: data })
  }
}
