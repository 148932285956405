







import Vue from 'vue'

import ProfileSettingsEmailNotifications from '@/components/profile/ProfileSettingsEmailNotifications.vue'
import ProfileSettingsBrowserNotifications from '@/components/profile/ProfileSettingsBrowserNotifications.vue'
import ProfileSettingsCurrentSubscriptions from '@/components/profile/Billing/ProfileSettingsCurrentSubscriptions.vue'

export default Vue.extend({
  name: 'ProfileSettings',
  components: {
    ProfileSettingsEmailNotifications,
    ProfileSettingsBrowserNotifications,
    ProfileSettingsCurrentSubscriptions
  },
  methods: {
    subscribe () {
      this.$emit('subscribe')
    }
  }
})
