





























































































































import NewUiModal from '@/components/NewUIComponents/NewUiModal.vue'
import Vue from 'vue'
import BaseInputVuelidate from '@/components/BaseInputVuelidate.vue'
import { mapActions } from 'vuex'
import { messages } from '@/i18n/messages'
import { required, email, minLength } from 'vuelidate/lib/validators'
import customEmailValidation from '@/validators/customEmailValidation'

export default Vue.extend({
  name: 'EditProfileModal',
  components: { NewUiModal, BaseInputVuelidate },
  props: {
    profile: {
      required: true
    }
  },
  data () {
    return {
      profileData: {
        name: { value: '', validity: false },
        last_name: { value: '', validity: false },
        email: { value: '', validity: false },
        phone: { value: '', validity: true },
        password_current: { value: '', validity: true },
        password_new: { value: '', validity: true },
        confirm_password: { value: '', validity: true }
      }
    }
  },
  validations: {
    profileData: {
      name: {
        value: {
          required
        }
      },
      last_name: {
        value: {
          required
        }
      },
      email: {
        value: {
          required,
          email,
          customEmailValidation
        }
      },
      phone: {
        value: {
          required,
          minLength: minLength(12)
        }
      },
      password_new: {
        value: {
          required
        }
      },
      confirm_password: {
        value: {
          required
        }
      }
    }
  },
  i18n: {
    messages
  },
  mounted () {
    this.profileData.name.value = this.profile.name
    this.profileData.last_name.value = this.profile.last_name
    this.profileData.email.value = this.profile.email
    this.profileData.phone.value = this.profile.phone
  },
  computed: {
    hasErrors () {
      return this.$v.$anyError
    }
  },
  methods: {
    ...mapActions('profile', ['updateProfile']),
    ...mapActions('notifications', ['addNotification', 'addSuccessNotification']),
    show () {
      this.$modal.show('edit-personal-info-modal')
    },
    changeValue (data) {
      this.profileData[data.key].value = data.value
      this.profileData[data.key].validity = data.validity
    },
    save_changes () {
      this.updateProfile({
        name: this.profileData.name.value,
        last_name: this.profileData.last_name.value,
        email: this.profileData.email.value,
        phone: this.profileData.phone.value,
        password_current: this.profileData.password_current.value,
        password_new: this.profileData.password_new.value,
        password_new_confirmation: this.profileData.confirm_password.value
      }).then(() => {
        this.$modal.hide('edit-personal-info-modal')
        this.addNotification({ type: 'success', body: 'Profile information updated.' })
      })
        .catch(er => {
          this.addNotification({
            body: er.response.data.errors[0].detail[0],
            type: 'danger'
          })
        })
    }
  }
})
