
































import Vue, { PropType } from 'vue'

import TabSwitcher from '@/components/TabSwitcher.vue'
import ProfileSettings from '@/components/profile/ProfileSettings.vue'
import ProfileBilling from '@/components/profile/Billing/ProfileBilling.vue'
import ProfileCoupons from '@/components/profile/ProfileCoupons.vue'
import ProfileInvoices from '@/components/profile/ProfileInvoices.vue'
import ProfileAffiliate from '@/components/profile/Affiliate/ProfileAffiliate.vue'
import BillingInfoEditModal from '@/components/profile/Billing/BillingInfoEditModal.vue'

import { mapGetters } from 'vuex'

export type TProfileTabs = 'settings' | 'billing' | 'coupons' | 'invoices' | 'affiliate'

type ITab = {
  id: number
  name: string
  title: string
  description?: string
}

const STATIC_TABS: Array<ITab> = [
  {
    id: 0,
    name: 'settings',
    title: 'Settings',
    description: 'Configure your profile settings and manage notifications.'
  },
  {
    id: 1,
    name: 'billing',
    title: 'Billing',
    description: 'Set up your billing information and control your subscription.'
  },
  {
    id: 2,
    name: 'coupons',
    title: 'Coupons',
    description: 'Use a coupon to activate a special offer.'
  },
  {
    id: 3,
    name: 'invoices',
    title: 'Invoices',
    description: 'Manage your invoices and track your payments status.'
  }
]

export default Vue.extend({
  name: 'ProfileTabs',
  data () {
    return {
      activeTabName: 'billing',
      activeTab: {
        id: 1,
        name: 'billing',
        title: 'Billing',
        description: 'Set up your billing information and control your subscription.'
      }
    }
  },
  props: {
    tab: {
      type: String as PropType<TProfileTabs>
    },
    affiliateAvailable: {
      type: [Boolean, undefined]
    }
  },
  components: {
    TabSwitcher,
    ProfileSettings,
    ProfileBilling,
    ProfileCoupons,
    ProfileInvoices,
    BillingInfoEditModal,
    ProfileAffiliate
  },
  methods: {
    switchTab (payload: { name: never, activeTab: never }) {
      if (this.activeTabName !== payload.name) {
        this.activeTab = payload.activeTab
        this.activeTabName = payload.name
        if (this.$route.name !== 'Profile Tab' || this.$route.params.tab !== this.activeTabName) {
          this.$router.replace({ name: 'Profile Tab', params: { tab: this.activeTabName } })
        }
      }
    }
    // showBillingModal () {
    //   this.$modal.show('edit-billing-info-modal')
    // }
  },
  computed: {
    ...mapGetters('profile', ['currentUser']),
    menu (): Array<unknown> {
      const menu = [...STATIC_TABS]

      if (this.affiliateAvailable) {
        menu.push({
          id: 4,
          name: 'affiliate',
          title: 'Affiliate'
        })
      }

      return menu
    }
  },
  created () {
    if (this.tab) {
      const tab = this.menu.find(tab => tab.name === this.tab)

      if (tab) {
        this.switchTab({
          name: this.tab,
          activeTab: tab
        })
      } else {
        this.$router.push({ name: 'Profile' })
      }
    }
  }
})
