










import ProfileBillingAvailableSubscriptions from './ProfileBillingAvailableSubscriptions.vue'
import ProfileSettingsCurrentSubscriptions from './ProfileSettingsCurrentSubscriptions.vue'

import Vue from 'vue'
import ProfileSubscribePaymentChoose from '@/components/profile/Billing/ProfileSubscribePaymentChoose.vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ProfileBilling',
  components: {
    ProfileBillingAvailableSubscriptions,
    ProfileSettingsCurrentSubscriptions
  },
  computed: {
    ...mapGetters('profile', ['currentUser'])
  },
  methods: {
    ...mapActions('plans', { subscribeAction: 'subscribe' }),
    ...mapActions('notifications', ['addNotification', 'addExceptions']),
    subscribe (params: {
      plan,
      period
    }) {
      if (this.currentUser?.stripe_account_id && this.currentUser?.paypal_account_id) {
        (this as any).$modal.show(
          ProfileSubscribePaymentChoose,
          {
            plan: params.plan,
            period: params.period,
            subscribe_handler: this.subscribe_request
          },
          {
            name: 'subscribe-choose-payment-system',
            height: 'auto',
            width: '800',
            adaptive: true,
            classes: 'ProfileSubscribeModal',
            overlayTransition: 'linear'
          }
        )
      } else if (this.currentUser?.stripe_account_id) {
        this.subscribe_request('stripe', params)
      } else if (this.currentUser?.paypal_account_id) {
        this.subscribe_request('paypal', params)
      }
    },
    openNewTab (href) {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: href
      }).click()
    },
    subscribe_request (ps, { period, plan }) {
      this.subscribeAction({ ps: ps, frequency: period.frequency, plan_id: plan.id })
        .then(response => {
          if (response.data.data?.hash && (response.data.data?.page || response.data.data?.url)) {
            localStorage.setItem('subscription_hash', response.data.data.hash)
            if (response.data.data?.need_execute) {
              localStorage.setItem('subscription_need_execute', response.data.data.need_execute)
            } else {
              localStorage.removeItem('subscription_need_execute')
            }
            let win = null
            if (response.data.data?.page) {
              win = window.open('about:blank', '_blank')
              win.document.write(response.data.data.page)
            } else {
              document.location.href = response.data.data.url
            }
            let timer = win && setTimeout(function tick () {
              if (win.closed) {
                location.reload()
              } else {
                timer = setTimeout(tick, 2000)
              }
            }, 2000)
          } else {
            this.addNotification({ type: 'danger', body: 'Subscription error, please try later or contact support' })
          }
        })
        .catch(er => {
          this.addExceptions(er.response.data)
        })
    }
  }
})
