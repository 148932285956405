


































































































































import Vue from 'vue'

import VSelect from '../../components/BaseComponents/VSelect/VSelect.vue'
import BaseLabelTag from '../../components/BaseLabelTag.vue'
import BasePagination from '../../components/BaseComponents/BasePagination/BasePagination.vue'
import { jsPDF } from 'jspdf'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ProfileInvoices',
  components: { BaseLabelTag, VSelect, DatePicker, BasePagination },
  data () {
    return {
      selectedFilterStatus: { code: 'all', label: 'All' },
      date_range: [],
      elementsQuantity: 5,
      disableButtons: false,
      page: 1,
      statusOptions: [
        { label: 'Paid', code: 'paid' },
        { label: 'Cancelled', code: 'cancelled' },
        { label: 'New', code: 'new' },
        { label: 'Unpaid', code: 'unpaid' },
        { label: 'All', code: 'all' }
      ],
      defaultStatus: { code: 'all', label: 'All' }
    }
  },
  watch: {
    elementsQuantity () {
      this.loadInvoices()
    }
  },
  methods: {
    ...mapActions('invoices', ['getBillingInvoices']),
    ...mapActions('notifications', ['addExceptions', 'addNotification']),
    downloadPdf (invoice) {
      const doc = new jsPDF()
      /* jshint ignore:start */
      doc.html(`<div style="border: 1px solid #ececec; width: 190px; padding: 5px">
                       <p style="font-size: 5px; color: #dd4b39">Invoice&nbsp;&nbsp;#${invoice.id}</p>
                       <p style="font-size: 6px; padding-top: 3px">Name&nbsp;&nbsp;invoice: ${invoice.attributes.name}</p>
                       <p style="font-size: 4px; padding-top: 2px">Description: ${invoice.attributes.description}</p>
                       <div style="height: 1px;border-top: 1px solid #b6b6b6; width: 100%;margin-top: 4px"></div>
                       <p style="display: flex; font-size: 4px; padding-top: 4px"><b style="display:block; width: 80px;">Amount:</b> ${invoice.attributes.amount}$</p>
                       <p style="display: flex; font-size: 4px; padding-top: 2px"><b style="display:block; width: 80px">Payment&nbsp;&nbsp;Due:</b> ${this.$moment(invoice.attributes.payment_due).format('MM/DD/YYYY')}</p>
                       <div style="height: 1px;border-top: 1px solid #b6b6b6; width: 100%;margin-top: 4px"></div>
                       <p style="font-size: 3px; padding-top: 6px">ConvertBomb | ${this.$moment(new Date()).format('MM/DD/YYYY HH:MM:ss')}</p>
                    </div>`, {
        callback: function (doc) {
          doc.save('invoice.pdf')
        },
        x: 10,
        y: 10
      })
      /* jshint ignore:end */
    },
    invoiceStatus (value: any) {
      switch (value) {
        case 0:
          return { text: 'Pending', type: 'warning' }
        case 1:
          return { text: 'Paid', type: 'success' }
        case 2:
          return { text: 'Unpaid', type: 'danger' }
        case 3:
          return { text: 'Cancel', type: 'danger' }
      }
    },
    chooseFilterStatus (acc: string) {
      this.selectedFilterStatus = acc
    },
    chooseElementsQuantity (quantity: number) {
      this.elementsQuantity = quantity
    },
    loadInvoices (page = 1) {
      this.disableButtons = true
      this.getBillingInvoices({
        status: this.selectedFilterStatus.code,
        start_date: this.date_range && this.date_range[0] ? this.date_range[0] : null,
        per_page: this.elementsQuantity,
        end_date: this.date_range && this.date_range[1] ? this.date_range[1] : null,
        page: page
      }).then((response) => {
        this.disableButtons = false
      }).catch(er => {
        this.disableButtons = false
        if (er.response?.data) {
          this.addExceptions(er.response.data)
        } else {
          this.addNotification({ type: 'danger', body: 'Oops! Something went wrong! Please try later' })
        }
      })
    },
    // TODO: изменить сброс на [] после правки на backend
    resetDateRange () {
      this.date_range = [
        this.$moment(new Date()).subtract(1, 'Y').format('YYYY-MM-DD'),
        this.$moment(new Date()).format('YYYY-MM-DD')
      ]
      this.loadInvoices()
    },
    clearFilters () {
      this.selectedFilterStatus = this.defaultStatus
      this.resetDateRange()
    }
  },
  computed: {
    ...mapGetters('invoices', [
      'getInvoices',
      'getPaginationInvoices'
    ])
  },
  created () {
    this.resetDateRange()
  }
})
