





















import Vue from 'vue'
import NewUiModal from '@/components/NewUIComponents/NewUiModal.vue'

export default Vue.extend({
  name: 'SuccessDeleteAccountModal',
  components: { NewUiModal },
  methods: {
    close () {
      this.$modal.hide('delete-account-modal')
      this.$router.push({ name: 'RestoreAccount' })
    }
  }
})
