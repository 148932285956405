



















import Vue from 'vue'
import NewUiModal from '@/components/NewUIComponents/NewUiModal.vue'

export default Vue.extend({
  name: 'SuccessRestore',
  components: { NewUiModal }
})
