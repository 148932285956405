



























import Vue, { PropType } from 'vue'
import VueApexCharts from 'vue-apexcharts'
import { RawLocation } from 'vue-router'

const ArrayRequired = {
  type: Array,
  required: true
}

const chartDefaultOptions = {
  chart: {
    id: 'basic-bar',
    zoom: { enabled: false },
    toolbar: { show: false },
    redrawOnParentResize: true,
    redrawOnWindowResize: true
  },
  dataLabels: { enabled: false },
  legend: {
    show: true,
    showForSingleSeries: false,
    position: 'top',
    horizontalAlign: 'left',
    fontSize: 14,
    labels: {
      colors: '#727272'
    },
    markers: {
      width: 16,
      height: 10,
      radius: 0
    }
  },
  stroke: {
    show: true,
    lineCap: 'butt',
    width: 1,
    dasharray: 0
  },
  markers: {
    size: 4,
    strokeWidth: 0
  },
  grid: { borderColor: '#EAEAEA' },
  yaxis: {
    axisBorder: {
      show: true,
      color: '#CFCFCF',
      offsetX: 0,
      offsetY: 0
    }
  }
}

export default Vue.extend({
  name: 'ProfileAffiliateChart',
  components: { VueApexCharts },
  props: {
    data: ArrayRequired,
    color: {
      type: String,
      default: '#888888'
    },
    dataKey: {
      type: String,
      required: true
    },
    route: {
      type: [String, Object] as PropType<RawLocation>,
      required: true
    }
  },
  computed: {
    dataKeyAsText () {
      return `${this.dataKey[0].toLocaleUpperCase()}${this.dataKey.replace('_', '-').slice(1)}`
    },
    options () {
      return [{
        id: 0,
        name: this.dataKeyAsText,
        color: this.color,
        data: this.data.map((value) => {
          return value[this.dataKey]
        })
      }]
    },
    categories () {
      return this.data.map((value) => {
        return value.date
      })
    },
    chartOptions () {
      return {
        ...chartDefaultOptions,
        xaxis: {
          categories: this.categories
        }
      }
    }
  }
})
