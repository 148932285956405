






















import Vue from 'vue'

import Modal from '@/components/DynamicModal.vue'
import BaseRadioButton from '@/components/BaseRadioButton.vue'
import BaseTextArea from '@/components/BaseComponents/BaseTextArea/BaseTextArea.vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default Vue.extend({
  name: 'UnsubscribeReasonModal',
  components: { Modal, BaseRadioButton, BaseTextArea },
  props: {
    plan: {
      required: true
    }
  },
  data () {
    return {
      reason: 3,
      reason_text: null
    }
  },
  computed: {
    ...mapGetters('profile', ['getAgreement']),
    ...mapGetters('exceptions', ['exceptionMessageBySourceParameter']),
    plan_name () {
      return this.plan?.name
    },
    valid_until () {
      return this.getAgreement?.next_billing_date ? moment(this.getAgreement.next_billing_date).format('YYYY-MM-DD') : null
    },
    reason_text_visible () {
      return this.reason === 3
    },
    reason_text_error () {
      return this.exceptionMessageBySourceParameter('reason_text')
    }
  },
  methods: {
    ...mapActions('plans', { unsubscribeAction: 'unsubscribe' }),
    ...mapActions('notifications', { add_exception_to_notification: 'addExceptions' }),
    ...mapActions('exceptions', ['addExceptions', 'clearExceptions']),
    unsubscribe () {
      this.unsubscribeAction({ reason: this.reason, reason_text: this.reason_text })
        .then(() => {
          this.$modal.hide('unsubscribe-reason-modal')
          location.reload()
        }).catch(er => {
          this.addExceptions(er.response.data)
          this.add_exception_to_notification(er.response.data)
        })
    },
    set_reason (v) {
      this.reason = v
      this.clearExceptions()
      this.reason_text = null
    },
    set_reason_text (data) {
      this.reason_text = data.value
      this.clearExceptions()
    }
  }
})
