






















import Vue, { PropType } from 'vue'
import ConfirmDeleteAccountModal from '@/components/profile/ConfirmDeleteAccountModal.vue'
import DeleteAccountModal from '@/components/profile/DeleteAccountModal.vue'
import SuccessDelete from '@/components/profile/SuccessDelete.vue'
import AffiliateService from '@/services/api/AffiliateService'
import ProfileInfoBlock from '@/components/profile/ProfileInfoBlock.vue'
import ProfileTabs, { TProfileTabs } from '@/components/profile/ProfileTabs.vue'
import ProfileEditModal from '@/components/profile/ProfileEditModal.vue'

import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Profile',
  data () {
    return {
      test: false,
      affiliateAvailable: undefined
    }
  },
  props: {
    tab: {
      type: String as PropType<TProfileTabs>
    }
  },
  components: {
    DeleteAccountModal,
    ConfirmDeleteAccountModal,
    ProfileInfoBlock,
    ProfileTabs,
    ProfileEditModal,
    SuccessDelete
  },
  methods: {
    ...mapActions('profile', [
      'getProfile',
      'toggleNewsNotifications'
    ]),
    ...mapActions('plans', [
      'getBillingInformation'
    ]),
    ...mapActions('coupons', [
      'getBillingCoupons'
    ]),
    showEditProfileModal () {
      this.$modal.show('edit-personal-info-modal')
    },
    showEditBillingInfoModal () {
      this.$modal.show('edit-billing-info-modal')
    }
  },
  created () {
    AffiliateService.getAffiliate()
      .then((res) => {
        if (res?.data?.data && res?.data?.data?.attributes?.status === 'active') {
          this.affiliateAvailable = true
        }
      })
    this.getBillingInformation()
    this.getProfile()
    this.getBillingCoupons()
  },
  computed: {
    ...mapGetters('profile', [
      'getName', 'currentUser'
    ]),
    ...mapGetters([
      'get_width',
      'get_sidebar_open_state'
    ])
  }
})
