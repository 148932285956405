



























import Vue from 'vue'

import PlanSelectPeriodSubscribe from '@/components/profile/Billing/PlanSelectPeriodSubscribe.vue'
import { mapActions, mapGetters } from 'vuex'
import { optionsPlanConverter, defaultPeriod } from '@/utils/Plans'
import PlanPrice from '@/components/profile/Billing/PlanPrice.vue'

export default Vue.extend({
  name: 'ProfileBillingAvailableSubscriptionsPlanInfo',
  components: { PlanSelectPeriodSubscribe, PlanPrice },
  data () {
    return {
      currentPlan: null
    }
  },
  props: {
    plan: {
      required: true
    }
  },
  computed: {
    ...mapGetters('profile', ['currentUser', 'getAgreement']),
    plan_button_text () {
      if (this.getAgreement && !this.getAgreement.end_date && Number(this.getAgreement.price) < Number(this.period.price)) {
        return 'Upgrade'
      } else {
        return 'Subscribe'
      }
    },
    period: {
      get () {
        return this.currentPlan ?? defaultPeriod(this.plan)
      },
      set (v) {
        this.currentPlan = v
      }
    },
    plansOptions () {
      return optionsPlanConverter(this.plan)
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification', 'addExceptions']),
    selecting (acc: any) {
      this.period = acc
    },
    subscribe () {
      this.$emit('subscribe', { plan: this.plan, period: this.period })
    }
  }
})
