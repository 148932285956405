
















import Vue from 'vue'
import BaseInputVuelidate from '@/components/BaseInputVuelidate.vue'
import { required } from 'vuelidate/lib/validators'
import { messages } from '../../i18n/messages'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'ProfileCouponsActivateForm',
  props: {
    cuppons: {
      required: true
    }
  },
  data () {
    return {
      couponValue: '',
      invalidCoupon: false
    }
  },
  validations: {
    couponValue: {
      required
    }
  },
  i18n: {
    messages
  },
  components: { BaseInputVuelidate },
  methods: {
    ...mapActions('coupons', ['activateCoupon']),
    activate_coupon () {
      this.activateCoupon(this.couponValue)
    },
    changeValue (data) {
      this.couponValue = data.value
    }
  }
})
