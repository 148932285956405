













import Vue from 'vue'
import IPlanPeriod from '@/models/IPlanPeriod'

export default Vue.extend({
  name: 'PlanPrice',
  props: {
    period: {
      required: true,
      type: Object as () => IPlanPeriod
    }
  }
})
