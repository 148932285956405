












































import Vue from 'vue'

export default Vue.extend({
  name: 'ProfileAffiliateCard',
  props: {
    card: {
      type: Object,
      required: true
    }
  }
})
