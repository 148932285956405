import { render, staticRenderFns } from "./ProfileSettingsBrowserNotifications.vue?vue&type=template&id=a48469da&scoped=true&"
import script from "./ProfileSettingsBrowserNotifications.vue?vue&type=script&lang=ts&"
export * from "./ProfileSettingsBrowserNotifications.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileSettingsBrowserNotifications.vue?vue&type=style&index=0&id=a48469da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a48469da",
  null
  
)

export default component.exports