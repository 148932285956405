























import Vue from 'vue'
import Modal from '@/components/DynamicModal.vue'
import ProfilePaymentSystem from '@/components/profile/Billing/ProfilePaymentSystem.vue'

export default Vue.extend({
  name: 'CustomModal',
  components: { Modal, ProfilePaymentSystem },
  props: {
    plan: {
      required: true
    },
    period: {
      required: true
    },
    subscribe_handler: {
      required: true,
      type: Function
    }
  },
  methods: {
    choosePaymentSystem (acc: any) {
      this.subscribe_handler(acc, { plan: this.plan, period: this.period })
    }
  }
})
