







import Vue from 'vue'
import WebPushService from '@/services/api/WebPushService'
import { mapActions, mapGetters } from 'vuex'

const pushStateComputing = 'computing'
const pushStateEnabled = 'enabled'
const pushStateDisabled = 'disabled'

export default Vue.extend({
  name: 'ProfileSettingsBrowserNotifications',
  data () {
    return {
      pushState: pushStateComputing
    }
  },
  methods: {
    ...mapActions('exceptions', ['addExceptions', 'clearExceptions']),
    ...mapActions('notifications', { addExceptionToNotifications: 'addExceptions' }),
    browserNotifications () {
      if (this.pushEnabled) {
        this.unsubscribe()
      } else {
        this.subscribe()
      }
    },
    urlBase64ToUint8Array (base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4)
      const base64 = (base64String + padding)
        .replace(new RegExp('-', 'g'), '+')
        .replace(/_/g, '/')

      const rawData = window.atob(base64)
      const outputArray = new Uint8Array(rawData.length)

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
      }
      return outputArray
    },
    updateSubscription () {
      navigator.serviceWorker.ready.then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
        .then(subscription => {
          this.setPushStateDisabled()
          if (!subscription) {
            if (this.currentUser.has_deleted_push_endpoint) {
              this.subscribe()
            }
            return
          }
          WebPushService.update(this.subscriptionData(subscription))
            .then(() => {
              this.setPushStateEnabled()
            })
            .catch(e => {
              if (e.response && e.response.data.errors) {
                this.addExceptions(e.response.data)
                this.addExceptionToNotifications(e.response.data)
              }
            })
        })
        .catch(e => {
          console.error('Error when updating the subscription', e)
        })
    },
    subscribe () {
      this.setPushStateComputing()
      navigator.serviceWorker.ready
        .then(serviceWorkerRegistration => {
          return serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(process.env.VUE_APP_WEB_PUSH_API_KEY)
          })
        })
        .then(subscription => {
          WebPushService.subscribe(this.subscriptionData(subscription)).then(() => {
            this.setPushStateEnabled()
          })
        }).catch(e => {
          if (Notification.permission === 'denied') {
            console.warn('Notifications are denied by the user.')
          } else {
            console.error('Impossible to subscribe to push notifications', e)
            if (e.response && e.response.data.errors) {
              this.addExceptions(e.response.data)
              this.addExceptionToNotifications(e.response.data)
            }
          }
          this.setPushStateDisabled()
        })
    },
    unsubscribe () {
      this.setPushStateComputing()
      navigator.serviceWorker.ready
        .then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
        .then(subscription => {
          if (!subscription) {
            this.setPushStateDisabled()
            return
          }

          WebPushService.unsubscribe(this.subscriptionData(subscription)).then(() => {
            subscription.unsubscribe()
              .then(() => {
                this.setPushStateDisabled()
              })
              .catch(er => console.log(er))
          })
        })
        .catch(e => {
          console.error('Error when unsubscribing the user', e)
          this.pushState = pushStateDisabled
        })
    },
    subscriptionData (subscription) {
      const key = subscription.getKey('p256dh')
      const token = subscription.getKey('auth')
      const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0]

      return JSON.stringify({
        endpoint: subscription.endpoint,
        publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
        authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
        contentEncoding,
        agent: navigator.userAgent
      })
    },
    setPushStateDisabled () {
      this.pushState = pushStateDisabled
    },
    setPushStateEnabled () {
      this.pushState = pushStateEnabled
    },
    setPushStateComputing () {
      this.pushState = pushStateComputing
    }
  },
  mounted () {
    if (Notification.permission === 'denied') {
      this.setPushStateEnabled()
    }
    navigator.serviceWorker.register(`${process.env.BASE_URL}serviceWorker.js`)
      .then(() => this.updateSubscription())
  },
  computed: {
    ...mapGetters('profile', ['currentUser']),
    pushAvailable () {
      return (('serviceWorker' in navigator) && ('PushManager' in window) && ('showNotification' in ServiceWorkerRegistration.prototype))
    },
    pushEnabled () {
      return this.pushState === pushStateEnabled
    },
    pushDisabled () {
      return this.pushState === pushStateDisabled
    },
    pushComputing () {
      return this.pushState === pushStateComputing
    },
    pushActionTitle () {
      switch (this.pushState) {
        case pushStateEnabled:
          return 'Disable Push notifications'
        case pushStateDisabled:
          return 'Enable Push notifications'
        case pushStateComputing:
        default:
          return 'Loading...'
      }
    }
  }
})
