






























import Vue from 'vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'

export default Vue.extend({
  name: 'PlanSelectPeriodSubscribe',
  components: { VSelect },
  props: {
    periods: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    plan_button_text: {
      type: String,
      required: true
    }
  },
  methods: {
    selecting (acc: any) {
      this.$emit('select_period', acc)
    },
    subscribe () {
      this.$emit('subscribe')
    }
  }
})
