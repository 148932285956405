




















































import Vue from 'vue'

import ProfileCouponsActivateForm from '@/components/profile/ProfileCouponsActivateForm.vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ProfileCoupons',
  components: { ProfileCouponsActivateForm },
  computed: {
    ...mapGetters('coupons', {
      coupons: 'getCoupons'
    })
  }
})
