











import Vue from 'vue'

export default Vue.extend({
  name: 'ProfilePaymentSystem',
  components: { },
  props: {
    name: {
      type: String,
      required: true
    },
    code: {
      type: String,
      required: true
    }
  },
  methods: {
    choosePaymentSystem () {
      this.$emit('choosePaymentSystem', this.code)
    }
  }
})
