



































import Vue from 'vue'
import Switcher from '@/components/Switcher.vue'
import Card from '@/components/Card.vue'
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  name: 'ProfileSettingsEmailNotifications',
  components: { Card, Switcher },
  data () {
    return {}
  },
  computed: {
    ...mapGetters('profile', ['currentUser'])
  },
  methods: {
    ...mapActions('profile', ['toggleNewsNotifications']),
    ...mapActions('notifications', ['addNotification', 'addSuccessNotification']),

    toggle (switcherState: boolean, switcherName: string) {
      let switchData = {}
      // data for disabled and enabled
      const forDisabledData = { setting_name: switcherName, setting_value: '0' }
      const forEnabledData = { setting_name: switcherName, setting_value: '1' }
      switchData = switcherState ? forDisabledData : forEnabledData
      // api request to change notification status (action from store)
      this.toggleNewsNotifications(switchData)
        .then(() => {
          this.addSuccessNotification('Success')
        })
        .catch((e) => {
          this.addNotification({ body: e.response.data.errors[0].detail[0], type: 'danger' })
        })
    }
  }
})
