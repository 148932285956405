

















































import BaseTextArea from '@/components/BaseComponents/BaseTextArea/BaseTextArea.vue'
import BaseRadioButton from '@/components/BaseRadioButton.vue'
import NewUiModal from '@/components/NewUIComponents/NewUiModal.vue'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'DeleteAccountModal',
  data: () => ({
    reasonValue: null,
    textReason: ''
  }),
  components: { BaseRadioButton, NewUiModal, BaseTextArea },
  computed: {
    ...mapGetters('profile', ['getReasonsList']),
    reasons () {
      return [...this.getReasonsList, {
        id: '-1',
        text: 'Other',
        type: 'other'
      }]
    },
    data () {
      return this.textReason ? {
        text: this.textReason
      } : {
        reason_id: this.reasonValue.id
      }
    }
  },
  methods: {
    ...mapActions('profile', ['deleteProfile', 'getDeleteReasonsList', 'getProfile']),
    ...mapActions('notifications', ['addNotification', 'addSuccessNotification']),
    deleteHandler () {
      this.deleteProfile(this.data).then(() => {
        this.$modal.hide('delete-account-modal')
        this.$modal.show('success-delete-account-modal')
        this.getProfile()
      }).catch(er => {
        this.addNotification({
          body: er.response.data.errors[0].detail[0],
          type: 'danger'
        })
      })
    }
  },
  async mounted () {
    await this.getDeleteReasonsList()
    this.reasonValue = this.reasons[0]
  },
  watch: {
    reasonValue () {
      this.textReason = ''
    }
  }
})
