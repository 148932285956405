

























import NewUiModal from '@/components/NewUIComponents/NewUiModal.vue'
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'RestoreAccountModal',
  components: { NewUiModal },
  methods: {
    ...mapActions('profile', ['accountRecovery', 'getProfile']),
    ...mapActions('notifications', ['addNotification']),
    async continueHandler () {
      try {
        this.$modal.hide('restore-account-modal')
        this.$modal.show('success-restore-account-modal')
        await this.accountRecovery()
        this.$modal.hide('success-restore-account-modal')
        await this.getProfile()
        await this.$router.push({ name: 'Profile' })
      } catch (e) {
        this.addNotification({
          body: e.response.data.errors[0].detail[0],
          type: 'danger'
        })
      }
    }
  }
})
