











import RestoreAccountModal from '@/components/profile/RestoreAccountModal.vue'
import SuccessRestore from '@/components/profile/SuccessRestore.vue'

export default {
  name: 'DeletingAccount',
  components: {
    RestoreAccountModal,
    SuccessRestore
  }
}
