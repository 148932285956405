























import NewUiModal from '@/components/NewUIComponents/NewUiModal.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'ConfirmDeleteAccountModal',
  components: { NewUiModal },
  methods: {
    continueHandler () {
      this.$modal.hide('confirm-delete-account-modal')
      this.$modal.show('delete-account-modal')
    }
  }
})
