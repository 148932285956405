













































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import UnsubscribeReasonModal from '@/components/profile/Billing/UnsubscribeReasonModal.vue'
import PlanSelectPeriodSubscribe from '@/components/profile/Billing/PlanSelectPeriodSubscribe.vue'
import moment from 'moment'
import { defaultPeriod, optionsPlanConverter } from '@/utils/Plans'
import PlanPrice from '@/components/profile/Billing/PlanPrice.vue'

export default Vue.extend({
  name: 'ProfileSettingsCurrentSubscriptions',
  components: { PlanSelectPeriodSubscribe, PlanPrice },
  data () {
    return {
      period: {}
    }
  },
  mounted () {
    this.period = defaultPeriod(this.currentUser.current_plan)
  },
  methods: {
    unsubscribe () {
      this.$modal.show(
        UnsubscribeReasonModal,
        {
          plan: this.currentUser.current_plan
        },
        {
          name: 'unsubscribe-reason-modal',
          height: 'auto',
          width: '700',
          adaptive: true,
          classes: 'ProfileFeedbackModal',
          overlayTransition: 'linear'
        }
      )
    },
    subscribe () {
      this.$emit('subscribe', { plan: this.currentUser.current_plan, period: this.period })
    },
    selectPeriod (acc: any) {
      this.period = acc
    }
  },
  computed: {
    ...mapGetters('profile', ['currentUser', 'getAgreement', 'getCurrentPlan']),
    nextBillingDate () {
      if (this.getCurrentPlan?.is_trial || !this.getAgreement) {
        return this.userPlanExpiresDate
      } else {
        return moment(this.getAgreement?.next_billing_date).format('YYYY-MM-DD')
      }
    },
    userPlanExpiresDate () {
      if (!this.currentUser.plan_expires_date) {
        return null
      }
      return moment(this.currentUser.plan_expires_date).format('YYYY-MM-DD')
    },
    agreementStartDate () {
      return this.getAgreement?.start_date ? moment(this.getAgreement.start_date).format('YYYY-MM-DD') : null
    },
    agreementEndDate () {
      return this.getAgreement?.end_date
    },
    paymentSystem () {
      return this.getAgreement?.ps
    },
    price () {
      return '$' + this.getAgreement.price
    },
    subscribeButtonsShow () {
      return !this.getCurrentPlan?.is_trial && (!this.getAgreement || this.getAgreement?.end_date)
    },
    unsubscribeButtonShow () {
      return !this.getCurrentPlan?.is_trial && this.getAgreement && !this.getAgreement?.end_date
    },
    billingDateTitle () {
      if (this.getCurrentPlan?.is_trial || !this.getAgreement) {
        return 'Active until:'
      } else {
        return 'Next billing date:'
      }
    },
    plansOptions () {
      return optionsPlanConverter(this.currentUser.current_plan)
    }
  }
})
