
















































































import AffiliateTable from '@/components/AffiliateTable.vue'
import Card from '@/components/Card.vue'
import ProfileAffiliateCard from '@/components/profile/Affiliate/ProfileAffiliateCard.vue'
import Vue from 'vue'
import COINS from '@/assets/icons/affiliate/coins.svg'
import GLOBE from '@/assets/icons/affiliate/globe.svg'
import TIMER from '@/assets/icons/affiliate/timer.svg'
import COPY from '@/assets/icons/copy-icon.svg'
import SUCCESS from '@/assets/ads-manager/access-icon.svg'
import AffiliateService, {
  IAffiliateData,
  IAffiliateTransactions,
  IAffiliateWithdrawals,
  IGraphicsData
} from '@/services/api/AffiliateService'
import ProfileAffiliateChart from '@/components/profile/Affiliate/ProfileAffiliateChart.vue'
import { RawLocation } from 'vue-router'
import { mapActions } from 'vuex'
import { AFFILIATE_ROUTES, IAffiliateTable } from '@/constants/Affiliate'

type ChartKeys = 'clicks' | 'sign_ups'
type CardKeys = 'payable' | 'pending' | 'earned' | 'purchases' | 'clicks'
type HEX = `#${string}`

interface DataCard {
  key: CardKeys
  route?: RawLocation
  title: string
  color: HEX
  icon: string
  value: number
}

interface Data extends Record<'COPY' | 'SUCCESS', string> {
  copied: boolean
  cards: Record<CardKeys, DataCard>
  charts: Record<ChartKeys, {
    key: ChartKeys
    value: IGraphicsData['data']
    color: HEX
    route: RawLocation
  }>
  tables: {
    transactions: IAffiliateTable
    withdrawals: IAffiliateTable
  },
  link: string,
  min_withdrawal: number
}

let timeoutId = 0

function clearTimeoutCopy () {
  if (timeoutId) {
    clearTimeout(timeoutId)
    timeoutId = 0
  }
}

const CLICK_TABLE_ROUTE = { name: 'Affiliate Clicks / Sign-ups' }

export default Vue.extend({
  name: 'ProfileAffiliate',
  components: {
    AffiliateTable,
    ProfileAffiliateCard,
    Card,
    ProfileAffiliateChart
  },
  data: (): Data => ({
    COPY,
    SUCCESS,
    copied: false,
    cards: {
      payable: {
        key: 'payable',
        title: 'Payable',
        color: '#00A65A',
        icon: COINS,
        value: NaN
      },
      pending: {
        key: 'pending',
        route: { name: 'Affiliate Pending' },
        title: 'Pending',
        color: '#F5A200',
        icon: TIMER,
        value: NaN
      },
      earned: {
        key: 'earned',
        route: { name: 'Affiliate Earned' },
        title: 'Earned',
        color: '#FF5454',
        icon: COINS,
        value: NaN
      },
      purchases: {
        key: 'purchases',
        route: { name: 'Affiliate Purchases' },
        title: 'Purchases',
        color: '#3F8CB7',
        icon: GLOBE,
        value: NaN
      },
      clicks: {
        key: 'clicks',
        route: CLICK_TABLE_ROUTE,
        title: 'Clicks',
        color: '#3F8CB7',
        icon: GLOBE,
        value: NaN
      }
    },
    charts: {
      clicks: {
        key: 'clicks',
        value: [],
        color: '#38ACFF',
        route: CLICK_TABLE_ROUTE
      },
      sign_ups: {
        key: 'sign_ups',
        value: [],
        color: '#12B1A0',
        route: CLICK_TABLE_ROUTE
      }
    },
    tables: {
      transactions: {
        head: AFFILIATE_ROUTES.find(page => page.key === 'pending')?.head || [],
        body: null,
        route: { name: 'Affiliate Pending' }
      },
      withdrawals: {
        head: AFFILIATE_ROUTES.find(page => page.key === 'earned')?.head || [],
        body: null,
        route: { name: 'Affiliate Earned' }
      }
    },
    link: '',
    min_withdrawal: Infinity
  }),
  computed: {
    ...Object.fromEntries(['payable', 'pending', 'earned', 'purchases', 'clicks']
      .map(key => [
        key, {
          get (): number {
            return this.cards[key].value
          },
          set (value: number): void {
            this.cards[key].value = value
          }
        }
      ])
    ) // as Record<CardKeys, { get (): number, set (value: number): void }>
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    resetData () {
      for (const key in this.cards) {
        this.cards[key].value = NaN
      }
      for (const key in this.charts) {
        this.charts[key].value = []
      }
      for (const key in this.tables) {
        this.tables[key].body = null
      }
      this.min_withdrawal = Infinity
    },
    getData () {
      this.resetData()
      const affiliate = AffiliateService.getAffiliate()
      const clicksChart = AffiliateService.getClicksChart()
      const signUpsChart = AffiliateService.getSignUpsChart()
      const transactions = AffiliateService.getTransactions()
      const withdrawals = AffiliateService.getWithdrawals()

      affiliate.then((res) => this.setAffiliate(res.data.data?.attributes))
      clicksChart.then((res) => this.setCharts('clicks', res.data.data))
      signUpsChart.then((res) => this.setCharts('sign_ups', res.data.data))
      transactions.then(res => this.setTransactionsTable(res.data?.data))
      withdrawals.then(res => this.setWithdrawalsTable(res.data?.data))

      Promise.allSettled([affiliate, clicksChart, signUpsChart, transactions, withdrawals])
        .then((promises) => {
          if (promises.find(promise => promise.status === 'rejected')) {
            this.addNotification({
              id: Date.now(),
              body: 'Something went wrong!',
              type: 'danger'
            })
          }
        })
    },
    setAffiliate (attr: IAffiliateData['attributes']): void {
      if (attr) {
        this.payable = attr.balance
        this.pending = attr.balance_pending
        this.earned = attr.earned
        this.purchases = attr.purchases
        this.clicks = attr.clicks
        this.link = attr.link
        this.min_withdrawal = attr.min_withdrawal
      }
    },
    timeoutCopy (): void {
      this.copied = false
      timeoutId = 0
    },
    copyLink (): void {
      navigator.clipboard.writeText(this.link)
      this.copied = true
      clearTimeoutCopy()
      timeoutId = setTimeout(this.timeoutCopy, 5000)
    },
    setCharts<T extends ChartKeys> (key: T, data: IGraphicsData<T>['data']): void {
      this.charts[key].value = data.length ? data : [
        {
          [key]: 0,
          date: 'No Data'
        }
      ]
    },
    setTransactionsTable (data?: IAffiliateTransactions['data']): void {
      this.tables.transactions.body = data
        ?.map(({ attributes }) => [attributes?.payable_at || '-', attributes.payout, attributes.status]) || null
    },
    setWithdrawalsTable (data?: IAffiliateWithdrawals['data']): void {
      this.tables.withdrawals.body = data
        ?.map(({ attributes }) => [attributes.created_at, attributes.wallet, attributes.amount, attributes.status]) || null
    }
  },
  mounted () {
    this.getData()
  },
  beforeDestroy: clearTimeoutCopy
})

